$client: sushimaki;
// SASS Backend by Lunchbox

@import "config";
@import "helpers";

@import "theme";
@import "client";

:root {
  --drawer-background: #{$drawer-background};
  --drawer-boxShadow: #{$drawer-boxShadow};
  --drawer-mask-background: #{$drawer-mask-background};
  --dialog-mask-background: #{$dialog-mask-background};
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: $background;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;

  @extend %body !optional;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1;
  @extend %fields !optional;
}

// necessary change to allow Links to keep the styling of their parent buttons.
a {
  color: inherit;
  text-decoration: inherit;
}

.universal-drawer {
  width: 100vw;

  @include media-breakpoint-up(sm) {
    width: 80vw;
  }
  @include media-breakpoint-up(md) {
    width: 80vw;
  }
  @include media-breakpoint-up(lg) {
    width: 50vw;
  }
  @include media-breakpoint-up(xl) {
    width: 50vw;
  }
  @include media-breakpoint-up(xxl) {
    width: 40vw;
  }
}
