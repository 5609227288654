:root {
  --font-1: "Ahkio-Bold";
  --font-2: "Makipelli-Bold";
  --font-3: "Makipelli-Extrabold";
  --font-4: "Trenda-Black";
  --font-5: "Trenda-Semibold";
}

@font-face {
  font-family: "Ahkio-Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Ahkio-Bold"), url("../../clients/#{$client}/fonts/Ahkio-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Makipelli-Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Makipelli-Bold"), url("../../clients/#{$client}/fonts/Makipelli-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Makipelli-Extrabold";
  font-style: normal;
  font-weight: 400;
  src: local("Makipelli-Extrabold"), url("../../clients/#{$client}/fonts/Makipelli-Extrabold.otf") format("opentype");
}

@font-face {
  font-family: "Trenda-Black";
  font-style: normal;
  font-weight: 400;
  src: local("Trenda-Black"), url("../../clients/#{$client}/fonts/Trenda-Black.otf") format("opentype");
}

@font-face {
  font-family: "Trenda-Semibold";
  font-style: normal;
  font-weight: 400;
  src: local("Trenda-Semibold"), url("../../clients/#{$client}/fonts/Trenda-Semibold.otf") format("opentype");
}
