:root {
  --focus-text-color: #32B8DF;
  --focus-ring-color: #FF8200;
}.base {
  
  
  background-color: #FFFFFF;
}
.baseButton {
  
  
  border-radius: 5px;
  background-color: #FFFFFF;
}
.baseBlue {
  
  
  border-radius: 5px;
  background-color: #32B8DF;
}
.baseBordered {
  &:active {
    border-color: #181D18;
  }
  
  border-width: 2px;
  border-style: solid;
  border-color: #181D18;
}
.baseBorderedBlue {
  
  
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #32B8DF;
}
.baseLighterGray {
  
  
  background-color: #F5F5F5;
}
.baseRounded {
  
  
  border-radius: 16px;
  background-color: #FFFFFF;
}
.accentPrimary {
  
  &:disabled {
    border-color: #5a5a5a;
  }
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #FF8200;
  background-color: #FF8200;
}
.accentYellow {
  
  
  background-color: #FFCD00;
}
.baseBackgroundGray {
  
  
  background-color: #F2F2F2;
}
.lightGray {
  
  
  background-color: #8C8E8C;
}
.lightGreen {
  
  
  background-color: #8BAB9B;
}
.baseWhiteBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #181D18;
  background-color: #FFFFFF;
}
.backgroundGrayBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #F2F2F2;
  background-color: #181D18;
}
.baseWhite {
  
  
  background-color: #FFFFFF;
}
.roundedbaseWhite {
  
  &:disabled {
    border-color: #5a5a5a;
  }
  border-radius: 3px;
  border-width: 0px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.roundedBaseGrayBordered {
  
  
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #6D6D6D;
}
.roundedBlackClearBackground {
  
  &:disabled {
    border-color: #5a5a5a;
  }
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #181D18;
}
.roundedWarningClearBackground {
  
  &:disabled {
    border-color: #5a5a5a;
  }
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #FF2A00;
}
.roundedAccentaccentPrimaryBackground {
  
  
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #FF8200;
  background-color: #FF8200;
}
.roundedBlueClearBackground {
  &:active {
    border-color: #32B8DF;
  }
  
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;
  border-color: #32B8DF;
  background-color: #FFFFFF;
}
.circleAccentClearBackground {
  
  
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #FF8200;
}
.circleGray {
  
  
  border-radius: 25px;
  border-width: 1px;
  border-style: solid;
  border-color: #6D6D6D;
  background-color: #181D18;
}
.circleBaseWhite {
  &:active {
    border-color: #6D6D6D;
  }
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #FFFFFF;
}
.circleBaseBlack {
  &:active {
    border-color: #6D6D6D;
  }
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #181D18;
}
.circleBase {
  
  
  border-radius: 100px;
}
.circleaccentPrimaryBackground {
  
  
  border-radius: 100px;
  background-color: #FF8200;
}
.circleAccentBlueBackground {
  &:active {
    border-color: #32B8DF;
  }
  
  border-radius: 100px;
  border-width: 3px;
  border-style: solid;
  border-color: #32B8DF;
  background-color: #32B8DF;
}
.circleAccentYellowBackground {
  &:active {
    border-color: #FFCD00;
  }
  
  border-radius: 100px;
  border-width: 3px;
  border-style: solid;
  border-color: #FFCD00;
  background-color: #FFCD00;
}
.clearBaseGrayBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #6D6D6D;
}
.clearLighterGrayBorderedRounded {
  
  
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #F5F5F5;
}
.clearaccentPrimaryBordered {
  &:active {
    border-color: #FF8200;
  }
  &:disabled {
    border-color: #FF8200;
  }
  border-width: 2px;
  border-style: solid;
  border-color: #FF8200;
}.title1 {font-family: "Ahkio-Bold";
  font-size: 2rem;
  font-weight: 700;}
.title2 {font-family: "Makipelli-Bold";
  font-size: 1.5rem;
  font-weight: 700;}
.title3 {font-family: "Trenda-Black";
  font-size: 1.25rem;
  font-weight: 700;}
.title4 {font-family: "Trenda-Black";
  font-size: 1rem;
  font-weight: 700;}
.title5 {font-family: "Trenda-Black";
  font-size: 1.5rem;
  font-weight: 800;}
.subheader1 {font-family: "Ahkio-Bold";
  font-size: 0.875rem;
  font-weight: 700;}
.subtitle1 {font-family: "Trenda-Black";
  font-size: 1.125rem;
  font-weight: 800;}
.subtitle2 {font-family: "Trenda-Black";
  font-size: 1.25rem;
  font-weight: 700;}
.body1 {font-family: "Trenda-Semibold";
  font-size: 1rem;
  font-weight: 400;}
.body2 {font-family: "Trenda-Semibold";
  font-size: 1rem;
  font-weight: 400;}
.buttontext1 {font-family: "Makipelli-Bold";
  font-size: 1.5rem;
  font-weight: 700;}
.buttontext2 {font-family: "Makipelli-Bold";
  font-size: 1rem;
  font-weight: 700;}
.buttontext3 {font-family: "Makipelli-Bold";
  font-size: 0.875rem;
  font-weight: 700;}.title1_baseBlack {@extend .title1;  color: #181D18;
  text-transform: initial;}
.title1_baseWhite {@extend .title1;  color: #FFFFFF;
  text-transform: initial;}
.title1_accentPrimary {@extend .title1;  color: #FF8200;
  text-transform: initial;}
.title2_baseBlack {@extend .title2;  color: #181D18;
  text-transform: uppercase;}
.title2_baseWhite {@extend .title2;  color: #FFFFFF;
  text-transform: uppercase;}
.title2_baseGray {@extend .title2;  color: #6D6D6D;
  text-transform: uppercase;}
.title2_accentPrimary {@extend .title2;  color: #FF8200;
  text-transform: uppercase;}
.title3_baseBlack {@extend .title3;  color: #181D18;
  text-transform: uppercase;}
.title3_baseWhite {@extend .title3;  color: #FFFFFF;
  text-transform: uppercase;}
.title3_baseGray {@extend .title3;  color: #6D6D6D;
  text-transform: uppercase;}
.title3_lightGray {@extend .title3;  color: #8C8E8C;
  text-transform: uppercase;}
.title3_accentPrimary {@extend .title3;  color: #FF8200;
  text-transform: uppercase;}
.title4_baseWhite {@extend .title4;  color: #FFFFFF;
  text-transform: uppercase;}
.title4_accentPrimary {@extend .title4;  color: #FF8200;
  text-transform: uppercase;}
.title4_baseBlack {@extend .title4;  color: #181D18;
  text-transform: uppercase;}
.title5_baseWhite {@extend .title5;  color: #FFFFFF;
  text-transform: uppercase;}
.title5_baseBlack {@extend .title5;  color: #181D18;
  text-transform: uppercase;}
.title5_baseWarning {@extend .title5;  color: #FF2A00;
  text-transform: uppercase;}
.title5_accentPrimary {@extend .title5;  color: #FF8200;
  text-transform: uppercase;}
.subheader1_baseBlack {@extend .subheader1;  color: #181D18;
  text-transform: initial;}
.subheader1_baseWhite {@extend .subheader1;  color: #FFFFFF;
  text-transform: initial;}
.subheader1_accentPrimary {@extend .subheader1;  color: #FF8200;
  text-transform: initial;}
.subtitle1_baseBlack {@extend .subtitle1;  color: #181D18;
  text-transform: initial;}
.subtitle1_baseWhite {@extend .subtitle1;  color: #FFFFFF;
  text-transform: initial;}
.subtitle1_accentPrimary {@extend .subtitle1;  color: #FF8200;
  text-transform: initial;}
.subtitle2_baseBlack {@extend .subtitle2;  color: #181D18;
  text-transform: initial;}
.subtitle2_baseWhite {@extend .subtitle2;  color: #FFFFFF;
  text-transform: initial;}
.subtitle2_baseGray {@extend .subtitle2;  color: #6D6D6D;
  text-transform: initial;}
.body1_baseBlack {@extend .body1;  color: #181D18;
  text-transform: initial;}
.body1_baseWhite {@extend .body1;  color: #FFFFFF;
  text-transform: initial;}
.body1_baseGray {@extend .body1;  color: #6D6D6D;
  text-transform: initial;}
.body1_lightGray {@extend .body1;  color: #8C8E8C;
  text-transform: initial;}
.body1_alternateGray {@extend .body1;  color: #5a5a5a;
  text-transform: initial;}
.body1_baseWarning {@extend .body1;  color: #FF2A00;
  text-transform: initial;}
.body1_accentPrimary {@extend .body1;  color: #FF8200;
  text-transform: initial;}
.body1_baseLink {@extend .body1;  color: #32B8DF;
  text-transform: initial;}
.body2_baseBlack {@extend .body2;  color: #181D18;
  text-transform: initial;}
.body2_baseWhite {@extend .body2;  color: #FFFFFF;
  text-transform: initial;}
.body2_baseGray {@extend .body2;  color: #6D6D6D;
  text-transform: initial;}
.body2_baseWarning {@extend .body2;  color: #FF2A00;
  text-transform: initial;}
.body2_accentPrimary {@extend .body2;  color: #FF8200;
  text-transform: initial;}
.body2_accentBlue {@extend .body2;  color: #32B8DF;
  text-transform: initial;}
.buttontext2_baseBlack {@extend .buttontext2;  color: #181D18;
  text-transform: uppercase;}
.buttontext2_baseGray {@extend .buttontext2;  color: #6D6D6D;
  text-transform: uppercase;}
.buttontext2_accentBlue {@extend .buttontext2;  color: #32B8DF;
  text-transform: uppercase;}.buttonPrimary {
  &:active {
    @extend .subheader1_baseWhite;
  }
  &:disabled {
    @extend .subheader1_baseWhite;
background-color: #5a5a5a;
  }
  @extend .subheader1_baseWhite;
@extend .roundedAccentaccentPrimaryBackground;
}
.buttonSecondaryStandard {
  &:active {
    @extend .title3_baseGray;
  }
  &:disabled {
    @extend .title3_baseGray;
background-color: #5a5a5a;
  }
  @extend .title3_baseBlack;
background-color: #FFFFFF;
@extend .roundedbaseWhite;
}
.buttonFooterStandardOrange {
  &:active {
    @extend .title2_baseBlack;
  }
  
  @extend .title2_baseBlack;
}
.buttonPrimaryClear {
  &:active {
    @extend .title2_baseWhite;
background-color: #FF8200;
  }
  
  @extend .title2_baseBlack;
@extend .base;
}
.buttonTertiaryStandard {
  &:active {
    @extend .title2_baseGray;
  }
  &:disabled {
    @extend .title2_baseGray;
background-color: #5a5a5a;
  }
  @extend .title2_baseBlack;
background-color: #FFFFFF;
@extend .roundedbaseWhite;
}
.buttonSecondaryWhite {
  &:active {
    @extend .title3_baseBlack;
background-color: #FFFFFF;
  }
  
  @extend .title3_baseWhite;
background-color: #181D18;
}
.buttonTertiaryGray {
  &:active {
    @extend .body1_baseBlack;
background-color: #6D6D6D;
  }
  
  @extend .body1_baseGray;
@extend .roundedBaseGrayBordered;
}
.buttonTertiaryAccent {
  &:active {
    @extend .body2_baseWhite;
background-color: #32B8DF;
  }
  
  @extend .body2_baseBlack;
@extend .roundedBlueClearBackground;
}
.buttonTertiaryBorderless {
  
  
  @extend .body2_baseBlack;
}
.cartEditBtn {
  
  
  @extend .buttontext2_accentBlue;
}
.cartRemoveBtn {
  
  
  @extend .buttontext2_baseGray;
}
.buttonTertiaryBorderlessaccentPrimary {
  
  
  @extend .subheader1_accentPrimary;
}
.buttonTertiaryBorderlessAccentBlue {
  
  
  @extend .buttontext2_accentBlue;
}
.buttonTertiaryLink {
  
  
  @extend .body1_baseLink;
}
.buttonQuarternaryLink {
  
  
  @extend .body1_accentPrimary;
}
.buttonUnaryDark {
  &:active {
    @extend .title3_baseWhite;
background-color: #181D18;
  }
  
  @extend .title3_baseBlack;
background-color: #FFFFFF;
@extend .roundedBlackClearBackground;
}
.buttonUnaryDarkCircle {
  &:active {
    @extend .title2_baseBlack;
  }
  
  @extend .title2_baseBlack;
background-color: #FFFFFF;
@extend .circleBase;
}
.buttonNegConfirm {
  &:active {
    @extend .title3_baseBlack;
  }
  
  @extend .title3_baseBlack;
@extend .roundedBlackClearBackground;
}
.buttonConfirm {
  &:active {
    @extend .title3_baseWhite;
  }
  
  @extend .title3_baseWhite;
background-color: #FF8200;
@extend .accentPrimary;
}
.buttonToggleStandard {
  &:active {
    @extend .title2_accentPrimary;
background-color: #FF8200;
  }
  
  @extend .title2_baseGray;
background-color: #F2F2F2;
@extend .circleAccentClearBackground;
}
.buttonToggleSecondary {
  &:active {
    @extend .title2_accentPrimary;
background-color: #FF8200;
  }
  
  @extend .title2_baseGray;
background-color: #FFFFFF;
@extend .circleAccentClearBackground;
}
.buttonaccentPrimary {
  &:active {
    @extend .title4_baseWhite;
background-color: #FF8200;
  }
  
  @extend .title4_baseWhite;
background-color: #FF8200;
@extend .accentPrimary;
}
.buttonNavStandard {
  &:active {
    @extend .subtitle2_baseWhite;
background-color: #FF8200;
  }
  
  @extend .subtitle2_baseGray;
background-color: #FFFFFF;
@extend .baseButton;
}
.buttonaccentPrimaryRound {
  &:active {
    @extend .subtitle1_baseBlack;
background-color: #FF8200;
  }
  &:disabled {
    @extend .subtitle1_baseBlack;
background-color: #FF8200;
  }
  @extend .subtitle1_baseBlack;
background-color: #FF8200;
@extend .circleaccentPrimaryBackground;
}.primary {
@extend .body1_baseBlack;
 &::placeholder {
    @extend .body1_alternateGray;
  }
}
.secondary {
@extend .body1_baseGray;
 &::placeholder {
    @extend .body1_baseBlack;
  }
}
.tertiary {
@extend .title1_accentPrimary;
 &::placeholder {
    @extend .body1_baseBlack;
  }
}